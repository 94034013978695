.BlockImageInfo {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    width: 90%;
    flex-direction: row;
    margin-bottom: -75px;
  }

  &-image {
    width: 100%;
    height: 500px;
    object-fit: cover;

    @media screen and (min-width: 768px) {
      width: 50%;
    }
  }

  &-content {
    width: 100%;
    display: flex;
    padding-top: 105px;

    @media screen and (min-width: 768px) {
      width: 50%;
    }
  }

  &-column {
    width: 50%;

    &:first-child {
      padding-left: 45px;
    }

    &:last-child {
      background-color: #fff450;
    }

    h5 {
      font-family: "Brandon Bold";
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
  }

  &-data {
    padding: 25px 0 0 35px;

    @media screen and (min-width: 768px) {
      padding: 68px 0 0 35px;
    }

    &-text {
      list-style: url('/assets/images/svgs/arrow-right.svg');
      font-size: 0.8rem;
      line-height: 0.9rem;

      & > span {
        position: relative;
        bottom: 7px;
      }

      .big {
        font-family: "Brandon Bold";
        text-transform: uppercase;
        font-size: 0.9rem;
        line-height: 1rem;
      }

      .green {
        font-family: "Brandon Bold";
        font-size: 0.9rem;
        color: #00B398;
      }
    }
  }
}
