.BlockMapAround {
  @media screen and (min-width: 768px) {
    width: 100%;
    height: 100vh;
    background-image: url('/assets/images/neighbourhood/map-around.jpg');
    background-size: cover;
    background-position: center;
  }

  &-image-mobile {
    display: block;

    @media screen and (min-width: 768px) {
      display: none !important;
    }
  }

  &-content {
    max-width: 1200px;
    margin: 0 auto;
    height: 100%;

    @media screen and (min-width: 768px) {
      width: 90%;
    }
  }

  &-title {
    font-size: 1.5rem;
    font-family: "Brandon Bold";
    font-weight: 100;
    padding-left: 55px;
    text-transform: uppercase;

    @media screen and (max-width: 768px) {
      width: 45%;
      margin-left: auto;
      margin-right: auto;
      padding-left: 31px;
    }
  }

  &-data {
    background-color: #fff450;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 35px 0;

    @media screen and (min-width: 768px) {
      max-width: 320px;
      margin-top: 45px;
      float: right;
    }
  }

  &-list {
    text-align: left;
    list-style-type: square;
    padding-left: 75px;

    @media screen and (max-width: 768px) {
      width: 45%;
      margin-left: auto;
      margin-right: auto;
    }

    &-item {
      font-size: 1.3rem;
      line-height: 1rem;
      margin-bottom: 15px;
      padding-right: 30px;
    }

    &-text {
      font-size: 0.9rem;
      line-height: 1rem;
      display: inline-block;
      vertical-align: top;
    }
  }
}
