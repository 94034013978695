.Home {
  cursor: url("/assets/images/cursor/auto.svg"), auto;
  position: relative;

  &-intro {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 55px);

    img {
      opacity: 1;
      width: 90%;
      max-width: 1200px;
      margin: 0 auto;
      height: calc(100vh - 205px);
      object-fit: cover;
    }
  }
}
