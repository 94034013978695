.Header {
  display: flex;
  justify-content: space-between;

  &-band {
    position: relative;
    z-index: 100;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 15px;

    @media screen and (min-width: 768px) {
      width: auto;
    }
  }

  &-logo {
    width: 150px;
  }

  &-menu {
    cursor: pointer;

    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  &-nav {
    @media screen and (max-width: 768px) {
      width: 100%;
      position: absolute;
      left: 0;
      right: 0;
      height: 100vh;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 75;
      text-align: center;
      top: -100vh;
      -webkit-transition: top 500ms;
      transition: top 500ms;

      &.open {
        top: 55px;
      }
    }

    @media screen and (min-width: 768px) {
      display: flex;
      align-items: center;
    }

    & ul li {
      list-style-type: none;
      display: block;

      @media screen and (min-width: 768px) {
        display: inline-block;
      }
    }
  }

  &-link {
    text-transform: uppercase;
    font-family: "Brandon Bold";
    font-size: 0.8rem;
    letter-spacing: 1px;
    padding: 0 20px;

    @media screen and (max-width: 768px) {
      margin: 75px 0;
    }

    > a {
      color: #1a1a1a;

      &:hover, &.active {
        text-decoration: underline;
        color: #009982;

        & svg {
          fill: #009982;
        }
      }
    }
  }

  &-icon {
    padding: 0 10px;
    height: 15px;
    width: 15px;

    @media screen and (max-width: 768px) {
      display: inline-block !important;
    }

    svg {
      height: 15px;
      width: 15px;
      fill: #000;
    }
  }

  &-lang {
    line-height: 18px;
    vertical-align: top;
    cursor: pointer;

    @media screen and (max-width: 768px) {
      display: inline-block !important;
    }
  }

  &-dash {
    width: 45px;
    height: 2px;
    background-color: rgba(0, 0, 0, 0.2);
    vertical-align: middle;
    margin: 0 auto;

    @media screen and (min-width: 768px) {
      margin: 0 35px 0 20px;
    }
  }
}
