.BlockMap {
  @media screen and (min-width: 768px) {
    height: 100vh;
    background-image: url('/assets/images/neighbourhood/map.jpg');
    background-size: cover;
    background-position: bottom;
  }

  &-image-mobile {
    display: block;

    @media screen and (min-width: 768px) {
      display: none !important;
    }
  }

  &-content {
    max-width: 1200px;
    margin: 0 auto;
    height: 100%;

    @media screen and (min-width: 768px) {
      width: 90%;
    }
  }

  &-title {
    font-size: 1.3rem;
    font-family: "Brandon Bold";
    padding-left: 35px;
  }

  &-data {
    background-color: #fff450;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 35px 0;

    @media screen and (min-width: 768px) {
      max-width: 320px;
      padding-top: 105px;
    }
  }

  &-table {
    display: inline-block;
    text-align: left;
    border-spacing: 0 10px;

    tr {
      td {
        padding: 5px 25px;
        font-size: 0.9rem;
      }

      td:first-child {
        background-color: #fff;
        font-family: "Brandon Bold";
        padding: 5px 10px 5px 37px;
      }

      td:last-child {
        font-family: "Brandon Bold";
      }
    }
  }
}
