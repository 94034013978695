.BlockPartners {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  @media screen and (min-width: 768px) {
    width: 90%;
    flex-direction: row;
  }

  &-item {
    padding: 45px 0 45px 25%;
    width: 100%;

    @media screen and (min-width: 768px) {
      padding: 155px 35px;
      width: 33.333333%;
    }

    &-logo {
      width: 150px;
      height: 125px;
      display: flex;
      align-items: center;
    }

    &-text {
      font-size: 0.8rem;
      margin: 0;
    }

    &-certification {
      height: 30px;
      position: relative;
      right: 3px;
    }
  }
}
