.Button {
  position: relative;
  display: inline-block;

  &-link {
    display: inline-block;
    padding: 15px 25px;
    margin-bottom: 15px;
    box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff450;
    text-transform: uppercase;
    color: #1a1a1a;
    font-size: 0.75rem;
    font-family: "Brandon Bold";

    @media screen and (min-width: 768px) {
      font-size: 0.9rem;
    }

    &:hover {
      background-color: #e6db47;
      cursor: pointer;
    }
  }

  &-image {
    position: absolute;

    &.right {
      right: -35px;
      top: 50%;
      margin-top: -20px;
    }

    &.bottom {
      transform: rotate(90deg);
      right: 0;
      left: 0;
      margin: 0 auto;
      bottom: -12px;
    }
  }
}
