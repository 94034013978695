.Axono {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }

  &-image {
    width: 100%;

    @media screen and (min-width: 768px) {
      width: 50%;
    }
  }

  &-data {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff450;

    @media screen and (min-width: 768px) {
      width: 50%;
      height: 100%;

      position: absolute;
      right: 0;
    }
  }

  &-title {
    font-size: 1.3rem;
    font-family: "Brandon Bold";
    padding-left: 35px;
  }

  &-table {
    display: inline-block;
    position: relative;
    right: 2px;
    text-align: left;
    border-spacing: 0 10px;

    thead {
      font-family: "Brandon Bold";
      font-weight: 100;
      font-size: 0.9rem;

      th {
        padding: 5px 15px;

        @media screen and (min-width: 768px) {
          padding: 5px 25px;
        }
      }
    }

    tr {
      td {
        padding: 5px 15px;
        font-size: 0.9rem;

        @media screen and (min-width: 768px) {
          padding: 5px 25px;
        }
      }

      td:first-child {
        background-color: #fff;
        font-family: "Brandon Bold";
        padding: 5px 10px 5px 37px;
      }

      td:last-child {
        font-family: "Brandon Bold";
      }
    }
  }

  &-insert {
    display: flex;
    justify-content: flex-end;

    @media screen and (min-width: 768px) {
      display: block;
    }

    &-content {
      background-color: #00b398;
      padding: 35px 65px;
      color: #fff;
      font-size: 1.1rem;
      width: 150px;
      margin: 45px 0 25px 0;

      @media screen and (min-width: 768px) {
        margin: 45px auto 25px auto;
      }
    }

    & .bold {
      font-size: 1.5rem;
    }
  }
}
