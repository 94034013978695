.BlockIcons {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  @media screen and (min-width: 768px) {
    width: 90%;
    flex-direction: row;
  }

  &-item {
    padding: 45px 0 45px 25%;
    width: 100%;

    @media screen and (min-width: 768px) {
      padding: 155px 35px;
      width: 25%;
    }

    &-icon {
      height: 150px;
      display: flex;
      align-items: flex-end;
    }

    &-text {
      font-size: 0.8rem;

      .big {
        font-family: "Brandon Bold";
        font-size: 1.4rem;
        line-height: 2rem;
      }
    }

    &:last-child {
      background-color: #fff450;
    }
  }
}
