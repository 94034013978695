.Contact {
  &-content {
    padding-left: calc(5% + 40px);
    padding-right: 5%;
    position: relative;
    z-index: 10;
    max-width: 1200px;
    margin-bottom: 135px;

    @media screen and (min-width: 768px) {
      padding-left: 300px;

      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  &-card {
    @media screen and (min-width: 768px) {
      padding-right: 45px;
      width: 33.333333%;
    }

    p {
      font-size: 0.9rem;
    }

    a {
      color: #1a1a1a;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    &-fullname {
      font-size: 1.25rem;
      font-family: "Brandon Bold";
      margin-bottom: 10px;
      margin-top: 55px;

      @media screen and (min-width: 768px) {
        margin-top: 0;
      }
    }

    &-icon {
      margin-right: 7px;
      position: relative;
      top: 3px;
      width: 20px;
      display: inline-block;
      text-align: center;
    }
  }
}
