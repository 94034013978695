.SectionHeading {
  position: relative;
  height: calc(100vh - 55px);
  width: 85%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: url("/assets/images/cursor/auto.svg"), auto;

  @media screen and (min-width: 768px) {
    width: 75%;
  }

  &-insert {
    height: 425px;
    width: 80%;
    max-width: 350px;
    background-color: #00b398;
    position: relative;
    margin-bottom: 125px;

    @media screen and (min-width: 768px) {
      margin-bottom: 0;
      height: 500px;
      width: 67%;
    }
  }

  &-titles {
    position: absolute;
    width: 100%;

    padding-left: 40px;
    padding-top: 65px;

    @media screen and (min-width: 768px) {
      padding-left: 120px;
      padding-top: 65px;
    }
  }

  &-title {
    font-family: "Brandon Bold";
    font-weight: 100;
    text-transform: uppercase;
    text-decoration: underline;
    font-size: 2.75rem;
    line-height: 3.25rem;
    margin-bottom: 0;

    @media screen and (min-width: 768px) {
      font-size: 5rem;
      line-height: 6rem;
    }
  }

  &-subtitle {
    font-size: 0.9rem;
    letter-spacing: 1px;

    @media screen and (min-width: 768px) {
      font-size: 1.1rem;
    }
  }

  &-image {
    display: none;

    @media screen and (min-width: 768px) {
      display: block;
      width: 90%;
      max-width: 350px;

      transform: translate(-25%, 0);
    }

    @media screen and (min-width: 992px) {
      transform: translate(-50%, 0);
    }
  }

  &-button {
    position: absolute;
    z-index: 10;
    max-width: 230px;
    bottom: -62px;
    left: 35%;
    width: 100%;

    @media screen and (min-width: 768px) {
      bottom: -65px;
      left: 100%;
    }
  }

  &-next {
    padding-top: 80px;
    height: 500px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(95%, -50%);

    @media screen and (min-width: 768px) {
      padding-top: 130px;
      transform: translate(110%, -50%);
    }

    &-title {
      font-family: "Brandon Bold";
      font-weight: 100;
      text-transform: uppercase;
      text-decoration: underline;
      font-size: 2.75rem;
      line-height: 3.25rem;
      margin-bottom: 0;
      opacity: 0.5;

      @media screen and (min-width: 768px) {
        font-size: 5rem;
        line-height: 6rem;
      }
    }
  }
}
