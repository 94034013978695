.BlockPerspective {
  height: 560px;
  background-size: cover;
  background-position: center;

  &-overlay {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow-x: hidden;
    background-size: cover;
    background-position: center;
    position: relative;

    @media screen and (min-width: 768px) {
      flex-direction: row;
      align-items: flex-end;
    }

    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    height: 100%;
  }

  &-info {
    color: #fff;
    margin: 45px 0;

    &-text {
      font-family: "Brandon Bold";
      font-size: 1.2rem;
      margin-bottom: 0;
    }

    &-subtext {
      margin: 0;
    }
  }

  &-button {
    margin-bottom: 45px;
    position: absolute;
    right: 55px;
    bottom: 0;
  }

  &.condensed {
    @media screen and (min-width: 768px) {
      width: 90%;
      margin: 0 auto;
    }

    .BlockPerspective-content {
      width: 100%;
      position: relative;
    }

    .BlockPerspective-info-text, .BlockPerspective-info-subtext {
      padding-left: 25px;
    }
  }
}
