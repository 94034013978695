.Layout {
  .MainContent {
    width: 100%;
    max-width: 1200px;
    padding: 15px;
  }
}

.ScrollToTop {
  &-content {
    background-color: #fff450;
    border-radius: 25px;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 55px;
    box-shadow: 2px 3px 0 rgba(0, 0, 0, 0.2);

    &:hover {
      background-color: #e6db47;
      cursor: pointer;
    }
  }

  &-arrow {
    transform: rotate(-90deg);
    width: 30px;
  }
}
