.Footer {
  &-brochure {
    background-color: #8C8C8C;

    &-content {
      padding: 35px;
      text-align: center;

      @media screen and (min-width: 768px) {
        text-align: left;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &-right {
      @media screen and (min-width: 768px) {
        padding-left: 35px;
      }
    }

    &-image {
      max-width: 350px;
      width: 90%;
    }

    &-text {
      font-size: 1.5rem;
      letter-spacing: 1px;
    }
  }

  &-nav {
    background-color: #1a1a1a;
    color: #fff;
    padding: 10px 0;

    & .Footer-content {
      padding: 5px 0;
      width: 90%;
      max-width: 1200px;
      margin: 0 auto;

      @media screen and (min-width: 768px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    &-left {
      margin-bottom: 20px;

      @media screen and (min-width: 768px) {
        display: flex;
        margin-bottom: 0;
      }
    }

    &-right {
      margin-top: 30px;

      @media screen and (min-width: 768px) {
        display: flex;
        align-items: center;
        margin-top: 0;
      }

      & p:last-child {
        position: relative;
        bottom: 5px;
      }
    }

    &-logo {
      width: 75px;
      fill: #fff;
      margin-right: 7px;
    }

    & p {
      font-family: "Brandon Regular";
      font-size: 0.6rem;
      vertical-align: middle;
      margin: 0;
    }

    &-link {
      font-family: "Brandon Bold";
      text-transform: uppercase;
      color: #00B398;
      margin-top: 6px;
      display: inline-block;

      @media screen and (min-width: 768px) {
        margin-top: 0;
      }

      &:hover {
        color: #009982;
      }
    }

    &-tactile {
      position: relative;
      top: 7px;
      margin-left: 7px;
      width: 35px;
    }

    &-separator {
      display: none;
      font-family: "Brandon Regular";
      font-size: 0.6rem;
      margin: 0 7px;

      @media screen and (min-width: 768px) {
        display: inline-block;
      }
    }
  }
}
