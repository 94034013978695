.Media {
  width: 90%;
  max-width: 1700px;
  margin: 0 auto;
  position: relative;

  &-title {
    position: absolute;
    z-index: 10;
    padding-top: 0;
  }

  &-gallery {
    margin-top: 10px;
    font-size: 0;

    @media screen and (min-width: 768px) {
      margin-top: 100px;
    }
  }

  &-image {
    display: inline-block;
    cursor: pointer;

    &:first-child {
      margin-left: 45vw;

      @media screen and (min-width: 768px) {
        margin-left: 22.5vw;
      }
    }

    & img {
      width: 45vw;
      height: 45vw;

      @media screen and (min-width: 768px) {
        width: 22.5vw;
        height: 22.5vw;
      }

      object-fit: cover;
    }
  }

  &-slider {
    .slick-slide {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 600px;
    }

    .slick-arrow {
      width: 35px;
      z-index: 50;
    }

    &-image {
      img {
        max-height: 600px;
      }
    }
  }

  &-modal {
    .rodal-dialog {
      background: transparent;
      box-shadow: none;
    }

    .rodal-mask {
      background: rgba(0, 0, 0, 0.7);
    }

    .rodal-close {
      background: url("/assets/images/svgs/close.svg");
      right: -9px;

      &:before, &:after, &:hover:before, &:hover:after {
        background: none;
      }
    }
  }
}
