@charset "UTF-8";
/* --- GLOBAL --- */
* {
  outline: none; }

body {
  margin: 0;
  font-family: "Brandon Regular";
  color: #1A1A1A;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  text-decoration: none; }

ul, li {
  margin: 0;
  padding: 0; }

img {
  max-width: 100%; }

sup {
  font-size: 0.7rem; }

/* --- POSITION --- */
.clear {
  clear: both; }

/* --- FONTS --- */
.bold {
  font-family: "Brandon Bold"; }

/* --- COLORS --- */
.white {
  color: #fff; }

.yellow {
  color: #fff450; }

/* --- SLIDER --- */
.slick-dots {
  opacity: 0;
  position: absolute;
  top: 50vh;
  /* poussé de la moitié de hauteur du référent */
  transform: translateY(-250px);
  transition: opacity 300ms 450ms;
  left: 15%;
  right: 0;
  bottom: 0;
  z-index: 50;
  text-align: left;
  height: 25px;
  width: 110px; }
  .slick-dots li button::before {
    display: none; }
  .slick-dots li {
    display: inline-block;
    height: 4px;
    width: 17px;
    background-color: #000; }
    .slick-dots li:first-child {
      display: none; }
    .slick-dots li.slick-active {
      background-color: #fff450; }
