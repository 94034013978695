.BlockImagesIcons {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1085px) {
    width: 90%;
    height: 500px;
    overflow-y: hidden;
    flex-direction: row;
  }

  &-column {
    width: 100%;
    position: relative;
    height: 500px;

    @media screen and (min-width: 1085px) {
      width: 50%;
    }

    &-overlay {
      background-color: rgba(0, 0, 0, 0.2);
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    &-content {
      position: absolute;
      top: 55px;

      &.first {
        right: 0;
      }

      &.second {
        width: 100%;
      }
    }
  }

  &-icons {
    display: flex;
    align-items: flex-end;
    height: 145px;
  }

  &-text {
    display: flex;

    p {
      width: 50%;
      font-size: 0.8rem;
      margin-top: 35px;

      .big {
        font-family: "Brandon Bold";
        font-size: 1.4rem;
        line-height: 2rem;
      }
    }
  }

  &-image {
    height: 500px;
    width: 100%;
    object-fit: cover;
  }

  .white {
    p { color: #fff; margin-left: 45px; }
  }

  .black {
    .BlockImagesIcons-column-content {
      @media screen and (max-width: 1085px) {
        right: 0;
        width: 90%;
      }
    }
  }
}
