.BlockImage {
  height: 70vh;
  background-size: cover;
  background-position: center;

  &-overlay {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
  }

  &-content {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 768px) {
      justify-content: flex-end;
    }

    &.left {
      @media screen and (min-width: 768px) {
        justify-content: flex-start;
      }
    }
  }

  &-info {
    @media screen and (min-width: 768px) {
      width: 50%;
    }
  }

  &-title {
    font-family: "Brandon Bold";
    font-weight: 100;
    text-decoration: underline;
    font-size: 2.75rem;
    line-height: 3.25rem;
    color: #fff;
    margin: 0;

    @media screen and (min-width: 768px) {
      font-size: 5rem;
      line-height: 5rem;
    }
  }

  &-text {
    color: #fff;
    font-size: 1rem;
    margin-bottom: 45px;

    @media screen and (min-width: 768px) {
      font-size: 1.3rem;
    }
  }

}
