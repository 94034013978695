.slick-slide {
  & .SectionHeading-next {
    transition: opacity 100ms;
    opacity: 0;
  }
}

.slick-active {
  & .SectionHeading-next {
    transition: opacity 750ms;
    opacity: 1;
  }
}

.fadeOut {
  animation: fadeOut 1s ease-in 3s 1 normal forwards;
}

@-webkit-keyframes fadeOut { 0% { opacity: 1; } 50% { opacity: 0; } 100% { opacity: 0; } }
        @keyframes fadeOut { 0% { opacity: 1; } 50% { opacity: 0; } 100% { opacity: 0; } }
