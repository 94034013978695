.BlockIllu {
  height: auto;

  @media screen and (max-width: 768px) {
    background-color: #00b398;
  }

  @media screen and (min-width: 768px) {
    height: 70vh;
  }

  &-content {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    @media screen and (min-width: 768px) {
      justify-content: flex-start;
      flex-direction: row;
    }
  }

  &-info {
    padding: 45px 0 75px 0;
    position: relative;
    z-index: 50;

    @media screen and (min-width: 768px) {
      width: 50%;
      padding: 0;
    }
  }

  &-title {
    font-family: "Brandon Bold";
    font-weight: 100;
    text-decoration: underline;
    font-size: 2.75rem;
    line-height: 3.25rem;
    color: #fff;
    margin: 0;

    @media screen and (min-width: 768px) {
      font-size: 5rem;
      line-height: 5rem;
    }
  }

  &-text {
    color: #fff;
    font-size: 1rem;
    margin-bottom: 45px;

    @media screen and (min-width: 768px) {
      font-size: 1.3rem;
    }

    & img {
      height: 25px;
      display: inline-block;
      vertical-align: middle;
    }
  }

  &-image {
    width: 70%;
    max-width: 400px;

    @media screen and (min-width: 768px) {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  &.left {
    .BlockIllu-content {
      @media screen and (min-width: 768px) {
        justify-content: flex-end;
      }
    }

    .BlockIllu-image {
      @media screen and (min-width: 768px) {
        left: 0;
      }
    }
  }

  &.full {
    background-color: #00b398;
  }

  &.condensed {
    .BlockIllu-content {
      background-color: #00b398;
      text-align: center;

      .BlockIllu-title, .BlockIllu-text {
        text-align: left;
      }

      @media screen and (min-width: 768px) {
        justify-content: center;
      }
    }
  }
}
