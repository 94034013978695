.Plan {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 500ms;

  &.active {
    opacity: 1;
    transition: opacity 500ms;
  }

  @media screen and (min-width: 768px) {
    height: 850px;
  }

  &-content {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (min-width: 768px) {
      width: 90%;
      flex-direction: row;
    }
  }

  &-insert {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff453;
    padding: 10% 10% 15% 10%;

    &-title {
      text-transform: uppercase;
      font-family: "Brandon Bold";
      font-size: 0.85rem;
      margin-bottom: 0;
    }

    &-text {
      margin: 0;
      font-size: 0.9rem;
    }

    &-number {
      font-family: "Brandon Bold";
      line-height: 1rem;
      margin-top: 30px;

      & .big {
        font-size: 2.5rem;
      }
    }
  }

  &-image {
    position: relative;
    width: 90%;
    margin: 140px auto 80px auto;

    @media screen and (min-width: 768px) {
      width: 45%;
      margin: 140px 15px 0 0;
    }
  }

  &-data {
    background-color: #00b398;
    height: 100%;
    width: 100%;
    display: flex;

    @media screen and (min-width: 768px) {
      width: 30%;
      display: block;
    }
  }

  &-table {
    display: inline-block;
    position: relative;
    right: 2px;
    border-spacing: 0 10px;
    padding-top: 215px;
    margin-bottom: 35px;

    @media screen and (min-width: 768px) {
      padding-top: 128px;
      margin-bottom: 0;
    }

    tr {
      td {
        padding: 5px 10px;
        font-size: 0.9rem;
      }

      td:first-child {
        background-color: #fff;
        font-family: "Brandon Bold";
        padding: 5px 10px 5px 0;
        width: 60px;
        text-align: right;
      }
    }
  }

  &-caption {
    width: 75%;
    margin: 0 auto;
    padding: 115px 0 115px 15px;

    @media screen and (min-width: 768px) {
      width: 25%;
      margin: 0;
      padding: 115px 0 0 15px;
    }
  }

  &-arrangement {
    display: inline-block;
    float: right;
    padding-right: 45px;
    order: 1;
    position: relative;
    z-index: 100;

    &-title {
      font-size: 1.4rem;
      font-family: "Brandon Bold";
      margin-bottom: 15px;
    }
  }
}
