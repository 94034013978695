.LegalNotices {
  &-content {
    padding-left: calc(5% + 40px);
    padding-right: 5%;
    position: relative;
    z-index: 10;
    max-width: 1200px;
    margin-bottom: 135px;

    @media screen and (min-width: 768px) {
      padding-left: 300px;
    }

    h5 {
      font-size: 1.25rem;
      font-family: "Brandon Bold";
      margin-bottom: 10px;
      margin-top: 55px;
    }

    p {
      font-size: 0.9rem;
    }

    a {
      color: #00B398;
      font-family: "Brandon Bold";
      text-decoration: underline;

      &:hover {
        color: #009982;
        text-decoration: none;
      }
    }

    ul {
      padding-left: 30px;
    }
  }
}
