.BlockTitle {
  position: relative;
  padding-left: 5%;
  padding-top: 45px;

  &-insert {
    height: 500px;
    width: 67%;
    max-width: 350px;
    position: absolute;
    background-color: #FFF450;
  }

  &-text {
    font-family: "Brandon Bold";
    font-weight: 100;
    position: relative;
    text-decoration: underline;
    font-size: 2.75rem;
    line-height: 3.25rem;
    z-index: 10;
    padding-left: 40px;

    @media screen and (min-width: 768px) {
      font-size: 5rem;
      padding-left: 120px;
      line-height: 6rem;
    }
  }
}
